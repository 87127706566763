export const InsuranceCompany = {
  Allianz: "allianz",
  Cardif: "cardif",
  CardifLife: "cardif_life",
  CardifArd: "cardif_ard",
  Hestia: "hestia",
  Versicherung: "versiecherung",
  Warta: "warta",
  Compensa: "compensa",
  Pzu: "pzu"
};
