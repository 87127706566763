import React from "react";
import styled from "styled-components";

import { hexToRgba } from "../helpers/colors";
import { colors, breakpoints } from "../UIGlobals";
import { Transitions } from "../theme";

import { Icon } from "./";

const Box = styled.div`
  background-color: ${props => (props.$background ? colors()[props.$background] : colors().white)};
  display: flex;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : "45px 40px 45px 35px")};
  border: 1px solid ${props => (props.border ? colors()[props.border] : "transparent")};
  box-shadow: ${props => (props.boxShadow ? "0px 5px 10px 0 rgba(38, 39, 42, 0.01)" : "none")};
  margin-bottom: 2px;
  @media (${breakpoints().maxL}) {
    padding: 50px 25px;
  }
`;

const BoxInner = styled.div`
  display: flex;
  flex-direction: ${props => (props.$flexDirection ? props.$flexDirection : "column")};
  padding-left: ${props => (props.paddingLeft ? `${props.paddingLeft}px` : "0")};
  padding-right: ${props => (props.paddingRight ? `${props.paddingRight}px` : "0")};
  padding-top: ${props => (props.paddingTop ? `${props.paddingTop}px` : "0")};
  padding-bottom: ${props => (props.paddingBottom ? `${props.paddingBottom}px` : "0")};
  align-items: ${props => (props.alignItems ? props.alignItem : "strech")};
  margin-top: ${props => (props.marginTop ? props.marginTop : "inherit")};
  flex-basis: ${props => (props.flexBasis ? props.flexBasis : "100%")};
  flex-grow: ${props => (props.flexGrow ? "1" : "0")};
  max-width: ${props => (props.maxWidth ? props.maxWidth : "none")};
`;

const BoxTitle = styled.div`
  color: ${props => (props.color ? colors()[props.color] : "deepGray")};
  font-size: ${props => (props.fontSize ? props.fontSize + "rem" : "1.6rem")};
  line-height: ${props => (props.lineHeight ? props.lineHeight : "1.25")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "600")};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom + "px" : "0")};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft + "px" : "0")};
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  margin-right: ${props => (props.marginRight ? `${props.marginRight}px` : "0")};
  word-spacing: ${props => (props.wordSpacing ? props.wordSpacing : "inherit")};
  ${props => props.flex && `
    display: flex;
    align-items: center;
  `}
`;

const BoxBodyText = styled.p`
  color: ${props => (props.color ? colors()[props.color] : "deepGray")};
  font-size: ${props => (props.fontSize ? props.fontSize + "rem" : "1.6rem")};
  line-height: ${props => (props.lineHeight ? props.lineHeight : "1.25")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "600")};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom + "px" : "0")};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft + "px" : "0")};
  @media (${breakpoints().maxL}) {
    padding-left: 78px;
  }
`;

const BoxTextSmall = styled.span`
  color: ${props => (props.color ? colors()[props.color] : colors().gray3)};
  font-size: 1.3rem;
  line-height: 1.31;
  margin-right: 5px;
  ${(props) => props.flex && `
    display: flex;
    align-items: center;
  `}
`;

const Caret = styled.div`
  margin-left: ${props => (props.fixMargin ? "125px" : "auto")};
  @media (${breakpoints().maxL}) {
    margin-left: 0;
    position: absolute;
    bottom: -25px;
    width: 100%;
    display: flex;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: ${Transitions.custom};
  cursor: pointer;
  position: relative;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};
  &:hover {
    opacity: 0.7;
  }
  @media (${breakpoints().maxS}) {
    flex-direction: ${props => (props.$mobileFlexColumn ? "column" : "row")};
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding ? props.padding : (props.$smallerPadding ? "15px 75px 0 80px" : "55px 75px 35px 80px")};
  display: ${props => (props.open ? "block" : "none")};
  transition: ${Transitions.custom};
  @media (${breakpoints().maxL}) {
    padding: 50px 0 0 0;
    margin-bottom: ${props => (props.$negativeMarginBottom ? "-50px" : "0")};
  }
`;

const BodyHeadline = styled.h3`
  font-size: 1.2rem;
  font-weight: 800;
  color: ${colors().black};
  text-transform: uppercase;
  padding-bottom: 25px;
  letter-spacing: 0.6px;
`;

const BoxRow = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${colors().dirtyWhite};
  border-bottom: 1px solid ${colors().dirtyWhite};
  border: ${props => (props.border ? props.border : "")};
  padding: ${props => (props.padding ? props.padding : "20px 0")};
  &:not(first-of-type) {
    border-top: none;
  }
  @media (${breakpoints().maxL}) {
    justify-content: ${props => (props.justifyContent ? props.justifyContent : "flex-start")};
  }
  @media (${breakpoints().maxS}) {
    flex-direction: column;
  }
`;

const Rounded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.$background ? colors()[props.$background]: colors().almostWhite};
  color: ${props => props.color ? colors()[props.color]  : colors().gray4};
  font-size: ${props => props.fontSize ? `${props.fontSize}px` : "inherit"};
  border-radius: 16.5px;
  border: ${props => props.border ? `1px solid ${colors()[props.border]}` : "0"};
  width: 100px;
  height: 33px;
  min-width: 65px;
  line-height: 14px;
  ${props => props.withPadding && `
    box-sizing: content-box;
    padding: 0 20px;
  `}
  &:not(:first-of-type){
    margin-left: 10px;
  }
  ${props => props.pinky && `
    background-color: ${colors().pink};
    color: ${colors().white};
    font-weight: 900;
  `}
  ${props => props.brandStyle && `
    background-color: ${hexToRgba(colors().brandBasic, 0.1)};
    color: ${() => colors().brandBasic};
  `}
  @media (max-width: 1024px){
    &:not(:first-of-type){
      margin-left: ${props => (props.marginLeft ? props.marginLeft : "0px")};
      margin-top: ${props => (props.marginTop ? props.marginTop : "10px")};
    }
  }
  @media (${breakpoints().maxS}) {
    margin-bottom: 10px;
  }
`;

const IconTextWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0")};
  @media (${breakpoints().maxM}) {
    margin-left: ${props => (!props.mobileNotFull ? "10px" : "25px")};
  }
  @media (${breakpoints().maxS}) {
    margin-left: 0;
  }
`;

const Text = styled.span`
  font-size: 1.2rem;
  color: ${props => (props.color ? colors()[props.color] : colors().black)};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft + "px" : "13px")};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom + "px" : "0px")};
  padding-top: 2px;
`;

const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  @media (${breakpoints().maxS}) {
    margin: 10px 10px 0 0;
  }
`;

const CertificateInlineBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = props => (
  <BodyWrapper
    $smallerPadding={props.body ? props.body.smallerPadding : null}
    $negativeMarginBottom={props.body ? props.body.negativeMarginBottom : null}
    {...props}
  />
);

const Head = props => (
  <HeadWrapper onClick={() => {
    if(props.toggle)  props.toggle();
    if(props.click)   props.click();
  }} {...props}>
    {props.children}
    {typeof props.open === "boolean" && (
      <Caret fixMargin={props.caret ? props.caret.fixMargin : null} justifyContent="center">
        <Icon icon="arrow_left" size={12} color={colors().gray9} rotation={props.open ? "90deg" : "-90deg"} />
      </Caret>
    )}
  </HeadWrapper>
);

const HeadCertificate = props => (
  <HeadWrapper onClick={() => {
    if(props.toggle)  props.toggle();
    if(props.click)   props.click();
  }} {...props} justifyContent="space-between" $mobileFlexColumn={true}>
    <CertificateInlineBox>
      {props.children[0]}
      {typeof props.open === "boolean" && (
        <Icon icon="arrow_left" size={12} color={colors().brandBasic} rotation={props.open ? "90deg" : "-90deg"} />
      )}
    </CertificateInlineBox>
    {props.children.slice(1)}
  </HeadWrapper>
);

export {
  Box,
  BoxInner,
  BoxTitle,
  BoxTextSmall,
  Caret,
  Head,
  Body,
  BoxRow,
  Rounded,
  IconTextWrap,
  BoxBodyText,
  ButtonWrap,
  Text,
  BodyHeadline,
  HeadCertificate,
};
