import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { simpleDateFromTimestamp } from "../../../../helpers/date";
import { selectKnfHistory } from "../../redux/idd.selectors";
import { ListPagination } from "../listPagination/listPagination.component";

import {
  Container,
  Wrapper,
  Image,
  Content,
  Title,
  List,
  Item,
  ItemHeader,
  ItemNumber,
  Activity,
  Dot,
  StyledSeparator,
  Prop,
  PropTitle,
  PropValue,
} from "./registryHistory.styled";

export const RegistryHistory = () => {
  const { t } = useTranslation();
  const knfHistory = useSelector(selectKnfHistory);
  const [itemsVisible, setItemsVisible] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pagesTotal = Math.ceil(knfHistory?.length / 3);

  useEffect(() => {
    if (knfHistory?.length > 3) {
      setItemsVisible(knfHistory.slice(0, 3));
    } else {
      setItemsVisible(knfHistory);
    }
  }, [knfHistory]);

  const handlePageChange = (page) => {
    setItemsVisible(knfHistory.slice(3 * (page - 1), 3 * (page - 1) + 3));
    setCurrentPage(page);
  };

  return (
    <Container>
      <Wrapper>
        <Image />
        <Content>
          <Title>{t("idd.registryHistory.title")}</Title>
          <List>
            {itemsVisible?.map(({ value, active, createdAt, startedAt, endedAt }) => (
              <Item key={`${value}-${startedAt}`}>
                <ItemHeader>
                  <ItemNumber>{value}</ItemNumber>
                  <Activity $isActive={active}>
                    {active ? t("idd.registryHistory.active") : t("idd.registryHistory.inactive")}
                    <Dot $isActive={active} />
                  </Activity>
                </ItemHeader>
                <StyledSeparator $narrow />
                <Prop>
                  <PropTitle>{t("idd.registryHistory.startDate")}</PropTitle>
                  <PropValue>
                    {`${t("idd.registryHistory.from")} `}
                    {simpleDateFromTimestamp(startedAt)}
                    {` ${t("idd.registryHistory.to")} `}
                    {endedAt ? simpleDateFromTimestamp(endedAt) : t("idd.registryHistory.current")}
                  </PropValue>
                </Prop>
                <Prop>
                  <PropTitle>{t("idd.registryHistory.creationDate")}</PropTitle>
                  <PropValue>{simpleDateFromTimestamp(createdAt)}</PropValue>
                </Prop>
              </Item>
            ))}
          </List>
          {knfHistory.length > 3 && (
            <ListPagination
              currentPage={currentPage}
              pagesTotal={pagesTotal}
              onPrevClick={() => handlePageChange(currentPage - 1)}
              onNextClick={() => handlePageChange(currentPage + 1)}
              onPageClick={handlePageChange}
            />
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};
